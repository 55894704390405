import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from 'js/components/ErrorMessage';
import format from 'date-fns/format';
import ClubLayout from 'js/layouts/ClubLayout';
import Form from './form.js';
import Survey from './survey.js';
import logOutIfLoggedIn from 'js/helpers/logOutIfLoggedIn';
import style from 'js/pages/PickSurvey/style.css';

class Staff extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        isStaffAreaOpen: PropTypes.bool,
        staffSurveyStartDate: PropTypes.string,
        staffSurveyEndDate: PropTypes.string,
    };

    state = {
        orgInfo: null,
    };

    onSubmit = orgInfo => {
        this.setState({ orgInfo });
    };

    redirectToHome = () => {
        this.props.history.push('/');
    };

    render() {
        const { isStaffAreaOpen, staffSurveyStartDate, staffSurveyEndDate } = this.props;

        if (!isStaffAreaOpen) {
            return (
                <ErrorMessage>
                    <div>
                        The Staff area is not currently open. Staff access is from{' '}
                        <b>
                            {format(staffSurveyStartDate, 'MMMM, D')} - {format(staffSurveyEndDate, 'MMMM, D')}
                        </b>
                    </div>
                </ErrorMessage>
            );
        }

        if (this.state.orgInfo) {
            return (
                <ClubLayout>
                    <Survey orgInfo={this.state.orgInfo} onComplete={this.redirectToHome} />
                </ClubLayout>
            );
        }

        return (
            <ClubLayout inBox logoInBox>
                <div className={style.title3}>NYOI Survey</div>
                <div className={style.title4}>Welcome, staff member!</div>
                <div className={style.block}>Use this website to access the NYOI 2025 Staff Survey.</div>
                <Form onSubmit={this.onSubmit} initialAccessCode={this.props.match.params.code || ''} />
            </ClubLayout>
        );
    }
}

const mapStateToProps = (state, props) => {
    const allowStaffAnyway = /allow=true/.test(props.location.search);

    return {
        isStaffAreaOpen: _get(state, 'app.params.data.staffSurveyOn') || allowStaffAnyway,
        staffSurveyStartDate: _get(state, 'app.params.data.dates.staffSurveyStartDate'),
        staffSurveyEndDate: _get(state, 'app.params.data.dates.staffSurveyEndDate'),
    };
};

export default logOutIfLoggedIn()(connect(mapStateToProps)(Staff));
