import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'js/pages/PickClub/Spinner';
import classnames from 'classnames';
import { client } from 'cccisd-apollo';
import getOrgByCodeQuery from './getOrgByCode.graphql';
import getOrgByIdQuery from './getOrgById.graphql';
import ClickButton from 'cccisd-click-button';

const requiredCodeErrorMessage = 'The Access Code is required';
const idErrorMessage = 'The ID you entered is invalid. Please try again.';

export default class Form extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        initialAccessCode: PropTypes.string,
    };

    state = {
        accessCode: this.props.initialAccessCode,
        isVerifyingCode: false,
        codeChanged: false,
        codeErrorMessage: requiredCodeErrorMessage,
        organizationInfo: {},
        showIdSearch: false,
        isVerifyingId: false,
        showIdErrorMessage: false,
        orgId: '',
    };

    componentDidMount() {
        if (this.state.accessCode) {
            this.onChange(this.state.accessCode);
        }
    }

    setInvalidCodeMessage = () => {
        this.setState({
            isVerifyingCode: false,
            codeErrorMessage: 'The Access Code you entered is invalid. Please try again.',
            organizationInfo: {},
        });
    };

    onChange = async value => {
        const lastUse = new Date().getTime();
        this.lastUse = lastUse;
        value = value.trim();

        if (!value) {
            this.setState({
                codeChanged: true,
                accessCode: '',
                isVerifyingCode: false,
                codeErrorMessage: requiredCodeErrorMessage,
            });
        } else {
            this.setState({
                codeChanged: true,
                accessCode: value,
                isVerifyingCode: true,
                codeErrorMessage: '',
            });

            // wait a bit just to not run ajax request so often
            await new Promise(resolve => setTimeout(resolve, 500));

            if (this.lastUse !== lastUse) {
                return;
            }

            if (!/^\d{6}$/.test(value)) {
                this.setInvalidCodeMessage();
                return;
            }

            const response = await client.query({
                query: getOrgByCodeQuery,
                variables: { code: value },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            if (this.lastUse !== lastUse) {
                return;
            }

            const { club, organization } = response.data.groups;

            if (!club.group.groupId && !organization.group.groupId) {
                this.setInvalidCodeMessage();
            } else {
                let orgInfo;
                if (organization.group.groupId) {
                    orgInfo = {
                        groupId: organization.group.groupId,
                        label: organization.group.label,
                        accessCode: organization.fields.orgAccessCode,
                        showGame:
                            organization.fields.showGameOnStaffSurvey === null
                                ? true
                                : organization.fields.showGameOnStaffSurvey,
                        clubs: organization.descendantGroups.clubList.map(item => ({
                            id: item.fields.clubId,
                            name: item.group.label,
                        })),
                    };
                } else {
                    orgInfo = {
                        groupId: club.ancestorGroups.organization.group.groupId,
                        label: club.ancestorGroups.organization.group.label,
                        accessCode: club.ancestorGroups.organization.fields.orgAccessCode,
                        showGame:
                            club.ancestorGroups.organization.fields.showGameOnStaffSurvey === null
                                ? true
                                : club.ancestorGroups.organization.fields.showGameOnStaffSurvey,
                        clubs: club.ancestorGroups.organization.descendantGroups.clubList.map(item => ({
                            id: item.fields.clubId,
                            name: item.group.label,
                        })),
                    };
                }

                this.setState({
                    isVerifyingCode: false,
                    codeErrorMessage: '',
                    organizationInfo: orgInfo,
                });
            }
        }
    };

    onSubmit = e => {
        e && e.preventDefault();

        if (this.state.codeErrorMessage || this.state.isVerifyingCode) {
            return;
        }
        if (!this.state.organizationInfo.groupId) {
            return;
        }

        this.props.onSubmit(this.state.organizationInfo);
    };

    onChangeId = e => {
        this.setState({ orgId: e.target.value, showIdErrorMessage: false });
    };

    showIdSearch = e => {
        e && e.preventDefault();
        this.setState(prev => ({ showIdSearch: !prev.showIdSearch }));
    };

    onSubmitId = async () => {
        const { orgId } = this.state;
        if (!orgId || !/^\d{5}$/.test(orgId)) {
            this.setState({
                showIdErrorMessage: true,
            });
        } else {
            this.setState({
                isVerifyingId: true,
            });

            const response = await client.query({
                query: getOrgByIdQuery,
                variables: { id: orgId },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            const { club, organization } = response.data.groups;

            if (!club.group.groupId && !organization.group.groupId) {
                this.setState({
                    showIdErrorMessage: true,
                    isVerifyingId: false,
                });
            } else {
                let orgInfo;
                if (organization.group.groupId) {
                    orgInfo = {
                        groupId: organization.group.groupId,
                        label: organization.group.label,
                        accessCode: organization.fields.orgAccessCode,
                        showGame:
                            organization.fields.showGameOnStaffSurvey === null
                                ? true
                                : organization.fields.showGameOnStaffSurvey,
                        clubs: organization.descendantGroups.clubList.map(item => ({
                            id: item.group.groupId,
                            name: item.group.label,
                        })),
                    };
                } else {
                    orgInfo = {
                        groupId: club.ancestorGroups.organization.group.groupId,
                        label: club.ancestorGroups.organization.group.label,
                        accessCode: club.ancestorGroups.organization.fields.orgAccessCode,
                        showGame:
                            club.ancestorGroups.organization.fields.showGameOnStaffSurvey === null
                                ? true
                                : club.ancestorGroups.organization.fields.showGameOnStaffSurvey,
                        clubs: club.ancestorGroups.organization.descendantGroups.clubList.map(item => ({
                            id: item.group.groupId,
                            name: item.group.label,
                        })),
                    };
                }

                this.setState({
                    isVerifyingId: false,
                    codeChanged: true,
                    accessCode: orgInfo.accessCode,
                    codeErrorMessage: '',
                    organizationInfo: orgInfo,
                    showIdSearch: false,
                });
            }
        }
    };

    render() {
        const {
            isVerifyingCode,
            accessCode,
            codeChanged,
            codeErrorMessage,
            organizationInfo,
            orgId,
            isVerifyingId,
            showIdErrorMessage,
        } = this.state;

        const showError = codeChanged && codeErrorMessage;

        let helpBlock = null;
        if (isVerifyingCode) {
            helpBlock = <Spinner />;
        } else if (showError) {
            helpBlock = codeErrorMessage;
        } else if (codeChanged) {
            helpBlock = (
                <span>
                    Your organization: <b>{organizationInfo.label}</b>
                </span>
            );
        }

        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div className={classnames('form-group', { 'has-error': showError })}>
                        <label className="control-label">
                            Please enter your 6-digit Organization Access Code or your 6-digit Club Access Code.
                        </label>
                        <input
                            className="form-control"
                            name="accessCode"
                            value={accessCode}
                            onChange={e => this.onChange(e.target.value)}
                            autoComplete="off"
                            autoFocus
                            maxLength="6"
                        />
                        {helpBlock && <span className="help-block">{helpBlock}</span>}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: '100%' }}
                        disabled={isVerifyingCode || codeErrorMessage}
                        onClick={this.onSubmit}
                    >
                        Confirm Access Code
                    </button>
                </form>

                <div style={{ marginTop: '2rem' }}>
                    Can&apos;t find your Access Code?
                    <br />
                    <a href="" onClick={this.showIdSearch}>
                        Try searching by Organization/Club ID
                    </a>{' '}
                    or contact your Organization Admin.
                </div>

                {this.state.showIdSearch && (
                    <form>
                        <div style={{ marginTop: '1em' }} className={showIdErrorMessage ? 'has-error' : ''}>
                            <label className="control-label">Search by Organization ID or Club ID</label>
                            <div style={{ display: 'flex' }}>
                                <input
                                    className="form-control"
                                    name="orgId"
                                    value={orgId}
                                    onChange={this.onChangeId}
                                    autoComplete="off"
                                    maxLength="5"
                                    autoFocus
                                />
                                <ClickButton
                                    title="Search"
                                    className="btn btn-primary"
                                    isLoading={isVerifyingId}
                                    style={{ marginLeft: '0.5em' }}
                                    onClick={this.onSubmitId}
                                />
                            </div>
                            {showIdErrorMessage && (
                                <div className="help-block" style={{ marginBottom: 0 }}>
                                    {idErrorMessage}
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </div>
        );
    }
}
