import React from 'react';
import PropTypes from 'prop-types';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        orgInfo: PropTypes.object,
        onComplete: PropTypes.func,
    };

    state = {
        pawnId: 0,
        pawnHash: '',
        loading: true,
    };

    componentDidMount = () => {
        this.getNewPawnId();
    };

    getNewPawnId = async () => {
        const params = {
            role: 'staffSurveyTaker',
            setAsBehalf: true,
            group: this.props.orgInfo.groupId,
            selfRoster: true,
        };

        const result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), params);

        this.setState({
            loading: false,
            pawnId: result.data.data.id,
            pawnHash: result.data.data.random_hash,
        });
    };

    render() {
        const { orgInfo } = this.props;
        const flowProps = {
            variables: { clubList: orgInfo.clubs },
        };

        if (orgInfo.showGame) {
            flowProps.variables.showStaffGame = 'yes';
        }

        return (
            <Loader loading={this.state.loading} removeChildren>
                <DeploymentPlayer
                    pawnId={this.state.pawnId}
                    pawnHash={this.state.pawnHash}
                    deploymentHandle="staff"
                    disableLayout
                    onComplete={this.props.onComplete}
                    allowLanguageChange={false}
                    shouldSaveLanguage={false}
                    shouldSaveFlowList={false}
                    flowProps={flowProps}
                />
            </Loader>
        );
    }
}
